.portfolio__hero {
    position: relative;
    background-image: url("../../assets/images/background_test.png");
    background-size: 100% 100%;
    display: flex;
    height: 850px;
    justify-content: center;
    align-items: center;
    gap: 60px;
    align-self: stretch;
}

.portfolio__hero-bg_overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(26, 24, 31, 0.8);
    z-index: 1;
}

.portfolio__hero-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
    flex: 1 0 0;
    z-index: 2;
}

.portfolio__hero-video {
    z-index: 3;
}

.portfolio__hero-content_title {
    font-family: var(--font-family);
    color: #fff;
    font-size: 52px;
    font-weight: 700;
    line-height: 50px;
    text-align: center;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.portfolio__hero-content h4 {
    font-family: var(--font-family);
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.portfolio__hero-content p {
    font-family: var(--font-family);
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.portfolio__hero-content_btns {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0 8px;
    gap: 8px;
    transform: scale(1);
    transition: background-color 0.3s ease, color 0.3s ease;
}

.portfolio__hero-content_btns button:hover {
    background-color: #FFF;
    color: var(--color-subtext);
}

.portfolio__hero-content_btns button {
    padding: 0.5rem 1rem;
    background-color: transparent;
    color: #FFF;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    border-radius: 5px;
    border: none;
    outline: 1px solid #FFF;
    cursor: pointer;
}

@media screen and (min-width: 2000px) {
    .portfolio__hero {
        height: 1400px;
    }
}

@media screen and (max-width: 1050px) {
    .portfolio__hero {
        height: 700px;
    }
}

@media screen and (max-width: 900px) {
    .portfolio__hero {
        height: 600px;
    }

    .portfolio__hero-content_title {
        font-size: 40px;
        line-height: 36px;
    }

    .portfolio__hero-content h4 {
        font-size: 14px;
        line-height: 18px;
    }
}

@media screen and (max-width: 450px) {
    .portfolio__hero {
        height: 450px;
    }

    .portfolio__hero-content {
        padding-top: 100px;
    }
    
    .portfolio__hero-content_title {
        font-size: 32px;
        line-height: 24px;
    }
}

@media screen and (max-width: 400px) {
    .portfolio__hero-content_title {
        font-size: 20px;
        line-height: 18px;
    }
}