.portfolio__project-container {
    width: 300px;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.portfolio__project-container img {
    width: 100%;
    height: auto;
}

.portfolio__project-container_overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 180px;
    color: #FFF;
    opacity: 0;
    transition: opacity 0.3s;
}

.portfolio__project-container:hover .portfolio__project-container_overlay {
    opacity: 1;
}

.portfolio__project-container_overlay h3,
.portfolio__project-container_overlay p {
    font-family: var(--font-family);
    color: #FFF;
    margin: 0;
}

.portfolio__project-container_overlay h3 {
    font-size: 24px;
    margin-bottom: 10px;
}

.portfolio__project-container_overlay p {
    font-size: 16px;
    margin-bottom: 20px;
}

.portfolio__project-container_overlay a {
    font-family: var(--font-family);
    color: #FFF;
    text-decoration: none;
}

@media screen and (max-width: 450px) {
    .portfolio__project-container {
        width: 200px;
        height: 200px;
    }
}