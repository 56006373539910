.portfolio__scheduler {
    width: 1200px;
}

@media screen and (max-width: 1050px) {
    .portfolio__scheduler {
        width: 900px;
    }
}

@media screen and (max-width: 900px) {
    .portfolio__scheduler {
        width: 700px;
    }
}

@media screen and (max-width: 450px) {
    .portfolio__scheduler {
        width: 100%;
        min-height: 300px;
    }

    .responsive-scheduler::-webkit-scrollbar {
        display: none;
    }
    
    .responsive-scheduler {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }
}