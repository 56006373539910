/* CursorFollower.css */
.cursor-follower {
    position: fixed;
    width: 35px; /* Adjust size as needed */
    height: 35px; /* Adjust size as needed */
    border: 2px solid rgb(93, 4, 4); /* Adjust border size and color as needed */
    border-radius: 50%; /* This keeps it circular */
    background-color: transparent; /* Ensures the inside of the circle is transparent */
    pointer-events: none; /* Important: this allows the cursor to 'click through' the element */
    transform: translate(-50%, -50%); /* Center the element on the cursor */
    z-index: 10;
  }
  