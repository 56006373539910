.portfolio__about {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    flex: 1 0 0;
}

.portfolio__about-heading h1 {
    color: #FFF;
    font-family: var(--font-family);
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
}

.portfolio__about-container {
    display: flex;
    padding: 20px 0;
    justify-content: center;
    align-items: center;
    gap: 40px;
}

.portfolio__about-container_link {
    text-decoration: none;
    color: inherit;
    display: block;
    width: 100%;
    transition: box-shadow 0.3s ease;
}

.portfolio__about-container_link:hover {
    box-shadow: 0px 4px 15px rgba(104, 104, 104, 0.8);
}

.portfolio__about-container_card {
    border-radius: 6px;
    border: 1px solid rgb(255, 255, 255);
    height: 100%;
}

.portfolio__about-container_card-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 30px;
    gap: 8px;
}

.portfolio__about-container_card-content h2 {
    font-family: var(--font-family);
    color: #FFF;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
}

.portfolio__about-container a:nth-child(2) .portfolio__about-container_card-content h2 {
    padding-top: 15px;
    padding-bottom: 15px;
}

.portfolio__about-container_card-content h3 {
    font-family: var(--font-family);
    color: var(--color-subtext);
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
}

@media screen and (max-width: 1050px) {
    .portfolio__about-container a:nth-child(2) .portfolio__about-container_card-content h2 {
        padding: 0;
    }
}

@media screen and (max-width: 900px) {
    .portfolio__about-container {
        gap: 20px;
    }

    .portfolio__about-container a:nth-child(2) .portfolio__about-container_card-content {
        padding-bottom: 60px;
    }
}

@media screen and (max-width: 450px) {
    .portfolio__about-container {
        flex-direction: column;
    }

    .portfolio__about-container a:nth-child(2) .portfolio__about-container_card-content {
        padding-bottom: 30px;
    }
}