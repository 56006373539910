.portfolio__projects {
    display: flex;
    flex-direction: column;
}

.portfolio__projects-title {
    width: 100%;
    text-align: center;
    margin-bottom: 50px;
}

.portfolio__projects-title h1 {
    color: #FFF;
    font-family: var(--font-family);
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
}

.portfolio__projects-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.portfolio__projects-container_github {
    margin-bottom: 20px;
}

.portfolio__projects-container_completed {
    flex: 1;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 200px;
    row-gap: 50px;
}

@media screen and (max-width: 1050px) {
    .portfolio__projects-container_completed {
        grid-gap: 50px;
    }
}

@media screen and (max-width: 900px) {
    .portfolio__projects-container_completed {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 50px;
    }
}

@media screen and (max-width: 450px) {
    .portfolio__projects-container_completed {
        display: flex;
        flex-direction: column;
    }
}