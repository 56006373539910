* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  background: var(--color-bg)
}

a {
  color: unset;
  text-decoration: none;
}

.gradient__bg {
  background: linear-gradient(115deg, rgba(131,152,167,1) 0%, rgba(70,114,144,1) 20%, rgba(10,68,107,1) 45%, rgba(10,68,107,1) 55%, rgba(70,114,144,1) 80%, rgba(131,152,167,1) 100%);
}

.section__padding {
  padding: 4rem 6rem;
}

.section__margin {
  padding: 4rem 6rem;
}

.scale-up-center {
-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
        animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@media screen and (max-width: 700px){
  .section__padding {
      padding: 4rem;
  }

  .section__margin {
      padding: 4rem;
  }
}

@media screen and (max-width: 550px){
  .section__padding {
      padding: 4rem 2rem;
  }

  .section__margin {
      padding: 4rem 2rem;
  }
}