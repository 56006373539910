.portfolio__contactform {
    display: flex;
    flex-direction: column;
    gap: 60px;
    justify-content: center;
    align-items: center;
    background-color: #1c1a24;
}

.portfolio__contactform-heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    text-align: center;
}

.portfolio__contactform-heading h1 {
    font-family: var(--font-family);
    color: #FFF;
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
}

.portfolio__contactform-heading p {
    font-family: var(--font-family);
    color: #FFF;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
}