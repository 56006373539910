@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@200;300;400;500;600;700;800&display=swap');


:root {
  --font-family: 'Roboto Mono', sans-serif;
  
  --color-bg: #1c1a24;
  --color-primary: #084672;
  --color-text: #064973;
  --color-subtext: #5C585C;
  --color-btn: #DE5A10;
}

/* #191920 */