.portfolio_certificates {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 60px;
}

.portfolio_certificates-heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
}

.portfolio_certificates-heading h1 {
    font-family: var(--font-family);
    color: #FFF;
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
}

.portfolio_certificates-heading p {
    font-family: var(--font-family);
    color: #FFF;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
}

.portfolio_certificates-container {
    display: flex;
    padding: 20px 0;
    justify-content: center;
    align-items: flex-end;
    align-content: flex-end;
    gap: 40px;
    flex-wrap: wrap;
}

.portfolio_certificates-container_content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 40px;
}

.portfolio_certificates-container_arrows {
    display:  none;
}

@media screen and (min-width: 2000px) {
    .portfolio_certificates-container {
        flex-direction: column;
    }
}

@media screen and (max-width: 1050px) {
    .portfolio_certificates-container_content {
        width: 920px;
    }
}

@media screen and (max-width: 800px) {
    .portfolio_certificates-container_content {
        display: flex;
        flex-direction: row;
        width: max-content;
        overflow-x: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        gap: 10px
    }
    
    .portfolio_certificates-container_content {
        width: 720px;
    }
}

@media screen and (max-width: 450px) {
    .portfolio_certificates-container_content {
        width: 350px;
        gap: 10px;
        justify-content: flex-start;
    }

    .portfolio_certificates-container {
        gap: 10px;
    }
}

@media screen and (max-width: 350px) {
    .portfolio_certificates-container_content {
        width: 275px;
        gap: 10px;
    }

    .portfolio_certificates-container {
        gap: 10px;
    }
}