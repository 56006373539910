.portfolio__certificate-container {
    display: flex;
    padding: 16px;
    justify-content: center;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    height: 300px;
    width: 300px;
    border-radius: 6px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    transform: scale(1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.portfolio__certificate-container:hover {
    transform: scale(1.25);
    box-shadow: 0px 4px 15px rgba(104, 104, 104, 0.8);
}

/* .portfolio__certificate-container_cert-link {
    text-decoration: none;
    color: inherit;
    display: block;
    width: 100%;
    transition: box-shadow 0.3s ease;
}

.portfolio__certificate-container_cert-link:hover {
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
} */

.portfolio__certificate-container_cert-image {
    flex: 1 0 0;
    width: 100px;
    height: 100px;
}

.portfolio__certificate-container_cert-image img {
    width: 100px;
    height: 100%;
}

.portfolio__certificate-container_cert-content {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.portfolio__certificate-container_cert-content h3 {
    text-align: center;
    font-family: var(--font-family);
    color: #FFF;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
}

.portfolio__certificate-container_cert-content p {
    text-align: center;
    font-family: var(--font-family);
    color: var(--color-subtext);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

@media screen and (max-width: 450px) {
    .portfolio__certificate-container {
        height: 200px;
        padding: 10px;
    }

    .portfolio__certificate-container:hover {
        transform: none;
        box-shadow: none;
    }
}