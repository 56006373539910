.portfolio__navbar {
    position: absolute;  /* Floats over the hero section */
    top: 0;
    width: 100%;
    background-color: transparent;  /* Starts off completely transparent */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px 10px 10px 10px;
    z-index: 10;
    transform: translateY(0);
    transition: transform 1s ease;
}

.portfolio__navbar:not(.visible) {
    transform: translateY(-100%);
}

.portfolio__navbar.scrolled {
    position: sticky;
    justify-content: center;
    top: 0;
    background-color: rgba(26, 25, 29, 0.8);  /* Semi-transparent background */
    padding: 20px 0 10px 0;
}

.portfolio__navbar.scrolled .portfolio__navbar-logo {
    display: none;  /* Logo disappears */
}

.portfolio__navbar.scrolled .portfolio__navbar-links_container {
    position: relative;
    justify-content: center;
    left: 0;
}

.portfolio__navbar-links {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
}

.portfolio__navbar-links_container p a {
    transition: opacity 0.3s ease;
    opacity: 1;
}

/* When a link is not hovered but others are, fade it */
.portfolio__navbar-links_container p a.faded {
    opacity: 0.5;
}

.portfolio__navbar-logo {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 10%;
}

.portfolio__navbar-logo img {
    width: 150px;
    height: 150px;
}

.portfolio__navbar-links_container {
    display: flex;
    flex-direction: row;
}

.portfolio__navbar-links_container p,
.portfolio__navbar-menu_container p {
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    text-transform: capitalize;
    margin: 0 1rem;
    cursor: pointer;
}

.portfolio__navbar-menu {
    margin-left: 1rem;
    display: none;
    position: relative;
}

.portfolio__navbar-menu svg {
    cursor: pointer;
}

.portfolio__navbar-menu_container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    text-align: center;
    background-color: rgba(26, 25, 29, 0.9);
    padding: 2rem;
    position: absolute;
    top: 40px;
    right: 0;
    margin-top: 1rem;
    width: 395px;
    border-radius: 5px;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.12);
    z-index: 20;
}

.portfolio__navbar-menu_container p {
    margin: 1rem 0;
}

@media screen and (max-width: 1050px) {
    .portfolio__navbar-logo img {
        width: 125px;
        height: 125px;
    }
}

@media screen and (max-width: 900px) {
    .portfolio__navbar {
        padding: 50px 30px 10px 10px;
    }

    .portfolio__navbar.scrolled {
        display: none;
    }
    
    .portfolio__navbar-links_container {
        display: none;
    }

    .portfolio__navbar-menu {
        display: flex;
    }
}

@media screen and (max-width: 550px) {
    .portfolio__navbar {
        padding: 40px 20px 10px 10px;
    }

    .portfolio__navbar-menu_container {
        top: 20px;
    }

    .portfolio__navbar-logo img {
        width: 90px;
        height: 90px;
    }
}

@media screen and (max-width: 400px) {
    .portfolio__navbar-menu_container {
        width: 345px;
    }
}

@media screen and (max-width: 350px) {
    .portfolio__navbar {
        padding: 20px 20px 10px 10px;
    }
    
    .portfolio__navbar-logo img {
        width: 70px;
        height: 70px;
    }

    .portfolio__navbar-menu_container {
        width: 285px;
    }
}