.portfolio__footer {
    text-align: center;
    padding: 15px;
}

.portfolio__footer-btn {
    background-color: transparent;
    font-family: var(--font-family);
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    color: white;
    border: none;
    padding: 2px 10px;
    border: 1px solid white;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 10px;   /* spacing between the button and the paragraph */
    transition: background-color 0.3s ease, color 0.3s ease;
  
    /* Optional hover effect */
    &:hover {
      background-color: #FFF;
      color: var(--color-subtext);
    }
  }  

.portfolio__footer p {
    font-family: var(--font-family);
    color: var(--color-subtext);
    font-size: 12px;
    font-weight: 700;
}